const en = {
  title: "Title",
  desc: "Knowledge system based on Zeroth Order Logic, hub for Anti-AI",
  home: "Home",
  search: "Search",
  close: "Close",
  abstract: "Abstract",
  content: "Content",
  displayName: "Display Name",
  titleLabel: "Title",
  add: "Add Proposition +",
  prove: "Prove",
  disprove: "Disprove",
  articles: "Articles",
  implies: "Proofs",
  knows: "Knows",
  reviews: "Reviews",
  save: "Save",
  about: "About",
  account: "Account",
  contact: "Contact",
  comment: "Comment",
  dashboard: "Dashboard",
  signout: "Sign Out",
  signin: "Sign In",
  add_proof: "Add Your Proof",
  show_more: "Show More",
  proposition: "Proposition",
  propositions: "Propositions",
  priori_proposition: "Priori Proposition",
  app: {
    title: "Scixiv",
  },
  proposition_form: {
    essence_label: "Share new Knowledge",
    details_placeholder: "More details about your proposition...",
  },
  dash: {
    edit_review: "Edit Review",
    my_blog: "My Blog",
    newArticle: "New Blog Article",
    writing: "Writing",
    publish: "Publish",
  },
  imply: {
    desc_propositions: "Propositions that can prove this knowedge",
  },
};

export default en;
export type LangType = typeof en;
