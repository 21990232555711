import type { LangType } from "./en";

const zh: LangType = {
  title: "标题",
  desc: "思合符契，基于零阶逻辑的推理系统, 反AI智慧中心 ",
  home: "主页",
  search: "搜索",
  close: "关闭",
  abstract: "摘要",
  content: "内容",
  displayName: "名称",
  titleLabel: "标题",
  add: "添加论点 +",
  prove: "证明",
  disprove: "证否",
  articles: "文章",
  implies: "论证",
  knows: "知识",
  reviews: "审议",
  save: "保存",
  about: "关于",
  account: "账户",
  contact: "联系",
  comment: "评论",
  dashboard: "面板",
  signout: "登出",
  signin: "登录",
  add_proof: "提出你的论证",
  show_more: "更多详情",
  proposition: "论点",
  propositions: "论点",
  priori_proposition: "先验命题",
  app: {
    title: "思合",
  },
  proposition_form: {
    essence_label: "分析新知识",
    details_placeholder: "详细说明...",
  },
  dash: {
    edit_review: "编辑审议",
    my_blog: "我的博客",
    newArticle: "创建博客文章",
    writing: "写作",
    publish: "发布",
  },
  imply: {
    desc_propositions: "证明这个知识所需的论点",
  },
};

export default zh;
