import * as i18n from "@solid-primitives/i18n";
import * as storage from "@solid-primitives/storage";
import * as router from "@solidjs/router";
import {
  type ParentComponent,
  createContext,
  // createEffect,
  createResource,
  startTransition,
  useContext,
} from "solid-js";
import { createStore } from "solid-js/store";
import { getRequestEvent } from "solid-js/web";

// en dictionary is loaded by default
import en_dict from "~/locales/en";
import zh_dict from "~/locales/zh";

type RawDictionary = typeof en_dict;

export type Locale = "en" | "zh-cn";

/*
for validating of other dictionaries have same keys as en dictionary
some might be missing, but the shape should be the same
*/
type DeepPartial<T> = T extends Record<string, unknown>
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : T;

const raw_dict_map: Record<Locale, DeepPartial<RawDictionary>> = {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
  en: en_dict, // en is loaded by default
  "zh-cn": zh_dict,
};

export type Dictionary = i18n.Flatten<RawDictionary>;

const en_flat_dict: Dictionary = i18n.flatten(en_dict);

async function fetchDictionary(locale: Locale): Promise<Dictionary> {
  if (locale === "en") return en_flat_dict;

  const dict = raw_dict_map[locale];
  const flat_dict = i18n.flatten(dict) as RawDictionary;
  return { ...en_flat_dict, ...flat_dict };
}

// Some browsers does not map correctly to some locale code
// due to offering multiple locale code for similar language (e.g. tl and fil)
// This object maps it to correct `langs` key
const LANG_ALIASES: Partial<Record<string, Locale>> = {
  // fil: "tl",
};

const toLocale = (string: string): Locale | undefined =>
  string in raw_dict_map
    ? (string as Locale)
    : string in LANG_ALIASES
    ? (LANG_ALIASES[string] as Locale)
    : undefined;

interface Settings {
  locale: Locale;
  // dark: boolean;
}

function initialLocale(location: router.Location): Locale {
  "use server";
  let locale: Locale | undefined;

  locale = toLocale(location.query.locale);
  if (locale) return locale;

  // locale = toLocale(navigator.language.slice(0, 2));
  // if (locale) return locale;

  // locale = toLocale(navigator.language.toLocaleLowerCase());
  // if (locale) return locale;

  return process.env.LOCALE || "en";
}

function initialSettings(location: router.Location): Settings {
  return {
    locale: initialLocale(location),
    // dark: window.matchMedia("(prefers-color-scheme: dark)").matches,
  };
}

function deserializeSettings(
  value: string,
  location: router.Location
): Settings {
  const parsed = JSON.parse(value) as unknown;
  if (!parsed || typeof parsed !== "object") return initialSettings(location);

  return {
    locale:
      ("locale" in parsed &&
        typeof parsed.locale === "string" &&
        toLocale(parsed.locale)) ||
      initialLocale(location),
    // dark:
    //   "dark" in parsed && typeof parsed.dark === "boolean"
    //     ? parsed.dark
    //     : false,
  };
}

interface AppState {
  // get isDark(): boolean;
  // setDark(value: boolean): void;
  get locale(): Locale;
  setLocale(value: Locale): void;
  t: i18n.Translator<Dictionary>;
}

const AppContext = createContext<AppState>({} as AppState);

export const useAppState = () => useContext(AppContext);

export const AppContextProvider: ParentComponent = (props) => {
  const location = router.useLocation();

  const now = new Date();
  const cookieOptions: storage.CookieOptions = {
    expires: new Date(now.getFullYear() + 1, now.getMonth(), now.getDate()),
    getRequest: getRequestEvent,
  };

  const [settings, set] = storage.makePersisted(
    createStore(initialSettings(location)),
    {
      storageOptions: cookieOptions,
      storage: storage.cookieStorage,
      deserialize: (value) => deserializeSettings(value, location),
    }
  );

  const locale = () => settings.locale;

  const [dict] = createResource(locale, fetchDictionary, {
    initialValue: en_flat_dict,
  });

  // createEffect(() => {
  //   document.documentElement.lang = settings.locale;
  // });

  // createEffect(() => {
  //   if (settings.dark) document.documentElement.classList.add("dark");
  //   else document.documentElement.classList.remove("dark");
  // });

  const t = i18n.translator(dict, i18n.resolveTemplate);

  const state: AppState = {
    // get isDark() {
    //   return settings.dark;
    // },
    // setDark(value) {
    //   set("dark", value);
    // },
    get locale() {
      return settings.locale;
    },
    setLocale(value) {
      void startTransition(() => {
        set("locale", value);
      });
    },
    t,
  };

  return (
    <AppContext.Provider value={state}>{props.children}</AppContext.Provider>
  );
};
